<template>
  <div class="vbox">
    <div class="page-container page-container-index">
      <div class="page-index">
        <div v-if="show">
          <video autoplay muted loop id="myVideo">
            <source src="@/assets/index.mp4" type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
          <div class="menu-index">
            <v-container class="info-index" v-if="false">
              <img
                src="@/assets/logo_2.svg"
                loading="lazy"
                width="190"
                alt=""
              />
              <p>
                {{
                  "Envios y pago contra entrega. Escoge la tarifa más económica"
                }}
              </p>
            </v-container>
            <v-container class="btns-index">
              <v-row>
                <v-col cols="6" class="pr-2">
                  <v-btn
                    color="white"
                    class="btn-huge"
                    block
                    href="https://app.vboxx.co/cotizar"
                  >
                    <img
                      src="@/assets/price.svg"
                      width="45"
                      loading="lazy"
                      alt="Icon tarifas"
                    />
                    <div>{{ "Ver tarifas" }}</div>
                  </v-btn>
                </v-col>
                <v-col cols="6" class="pl-2">
                  <v-btn
                    color="white"
                    class="btn-huge"
                    block
                    href="https://app.vboxx.co/guia"
                  >
                    <img
                      src="@/assets/new_guide.svg"
                      width="45"
                      loading="lazy"
                      alt="Icon new guide"
                    />
                    <div>{{ "Nueva guia" }}</div>
                  </v-btn>
                </v-col>
              </v-row>

              <v-row class="mt-0">
                <v-col cols="12">
                  <v-btn
                    color="green accent-3 btn-main"
                    height="60"
                    block
                    href="https://app.vboxx.co/login"
                  >
                    {{ "Ingresar" }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </div>
        <div v-else>CARGANDO</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  created() {
    this.show = true;
  },
};
</script>

<style lang="scss" scoped>
.page-index {
  position: relative !important;
  display: inline-block;
  height: 665px !important;
  #myVideo {
    height: 665px !important;
    background-size: cover;
    // filter: brightness(75%);
    // transform: translate(-35%, 0px) scale(100%);
    transform: translate(0px, -95px) scale(100%);
  }

  .menu-index {
    position: absolute;
    bottom: 0;
    width: 100%;
    .info-index {
      background-color: transparent !important;
      margin-bottom: 82px !important;
      padding-bottom: 0 !important;
      padding-top: 0 !important;
      p {
        color: white;
        font-size: 22px;
        width: 330px;
      }
    }
    .btns-index {
      background-color: #f6f6f6;
      border-radius: 15px 15px 0px 0px;

      > .row:first-child {
        margin-top: -100px;
      }
      .v-btn {
        border-radius: 10px !important;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: normal;
      }
      .btn-huge {
        height: 125px;
        text-align: center !important;
      }
    }
  }
}
@media (max-width: 450px) {
  .page-index {
    height: 100% !important;
    #myVideo {

      transform: translate(0px, -80px) scale(100%);
      position: fixed !important;
      height: 100% !important;
      width: 100% ;
    
    }
  }
  .menu-index {
    position: fixed !important;
  }
}
</style>

<style lang="scss">
.page-index {
  .btn-huge > span {
    display: block !important;
  }
  .btn-huge > span > img {
    margin-bottom: 2px;
  }
}
</style>
