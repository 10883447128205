<template>
  <v-app>
    <div class="vboxx-main_body">
      <div class="vboxx-wrap_mobile">
        <div class="vboxx-wrap_content">
          <!-- <router-view /> -->
          <IndexPage />
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import IndexPage from "@/views/indexPage.vue";
export default {
  name: "App",
  components: {
    IndexPage,
  },

  data: () => ({
    //
  }),
};
</script>
